<template>
  <div>
    <v-app-bar
      flat
      :color="
        updateButtonController.status == 'running' ? 'amber lighten-3' : 'white'
      "
      class="x-scroll"
    >
      <div class="pa-2">
        <v-btn
          :class="'font-weight-bold ' + updateButtonController.color"
          :disabled="updateButtonController.disabled"
          @click="onUpdateButton"
          ><v-icon left>{{ updateButtonController.icon }}</v-icon
          >{{ updateButtonController.label }}</v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn
          class="info font-weight-bold"
          dense
          @click="onSubmit"
          :disabled="updateButtonController.saveDisabled"
        >
          <v-icon left>mdi-content-save</v-icon>保存</v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn
          class="info font-weight-bold"
          dense
          @click="onSetting"
          :disabled="updateButtonController.saveDisabled"
          ><v-icon left>mdi-cog</v-icon>設定</v-btn
        >
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup(props, ctx) {
    const state = reactive({
      updateButtonController: {
        disabled: false,
        status: "stopping",
        label: "自動更新を開始",
        icon: "mdi-timer-outline",
        color: "info",
        saveDisabled: false,
      },
    });

    const action = (params) => {
      switch (params.func) {
        case "editmode_set":
          if (params.mode == "new") {
            state.updateButtonController.disabled = true;
          } else {
            state.updateButtonController.disabled = false;
          }
          break;
        case "auto_set":
          if (params.mode) {
            state.updateButtonController.label = "自動更新中(停止)";
            state.updateButtonController.status = "running";
            state.updateButtonController.icon = "mdi-timer-off-outline";
            state.updateButtonController.color = "error";
            state.updateButtonController.saveDisabled = true;
          } else {
            state.updateButtonController.label = "自動更新を開始";
            state.updateButtonController.status = "stopping";
            state.updateButtonController.icon = "mdi-timer-outline";
            state.updateButtonController.color = "info";
            state.updateButtonController.saveDisabled = false;
          }
      }
    };

    const onUpdateButton = async () => {
      ctx.emit("update_button");
    };
    const onSubmit = async () => {
      ctx.emit("save");
    };
    const onSetting = async () => {
      ctx.emit("setting");
    };

    return {
      ...toRefs(state),
      action,
      onUpdateButton,
      onSubmit,
      onSetting,
    };
  },
});
</script>
